import React from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from 'core/layout';
import MetaTags from 'core/meta-tags/index';
import Pagination from 'atomic-design/organisms/pagination';
import GuideEntries from 'atomic-design/organisms/guide-entries';
import PageSection from 'atomic-design/atoms/page-section';
import theme from 'styles/theme';
import PageHeader from 'atomic-design/atoms/page-header';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const expertGuidesQuery = graphql`
  query expertGuidesPage($skip: Int!, $limit: Int!) {
    allContentfulPage(filter: { url: { eq: "expert-guides" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
          metatagImage {
            file {
              url
            }
          }
          metatagTitle
          metatagDescription
        }
      }
    }
    allContentfulGuide(sort: { fields: [publishedDate], order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          title
          subtitle
          slug
          publishedDate
          hero {
            contentful_id
          }
        }
      }
    }
  }
`;
const Guides = props => {
  const { data, location } = props;
  const { currentPage, numPages } = props.pageContext;
  const pageHeaderData = data.allContentfulPage.edges[0].node;

  const metaTags = {
    title: pageHeaderData.metatagTitle,
    description: pageHeaderData.metatagDescription,
    image: pageHeaderData.metatagImage.file.url,
  };

  return (
    <Layout path={location.pathname}>
      <MetaTags
        charSet="utf-8"
        viewport="width=device-width, initial-scale=1"
        type="website"
        {...metaTags}
      />
      <PageSection>
        <PageHeader
          title={pageHeaderData.headerSubtitle}
          subTitle={documentToReactComponents(pageHeaderData.headerTitle.json)}
          color={theme.newTheme.color.gradient.text}
        />
      </PageSection>

      <PageSection
        padding={`${theme.newTheme.spacing(5)} 0 ${theme.newTheme.spacing(20)} 0`}
        mobilePadding={`0 0 ${theme.newTheme.spacing(15)} 0`}
      >
        <GuideEntries posts={data.allContentfulGuide.edges} />
      </PageSection>
      <PageSection margin={`${theme.newTheme.spacing(4)} 0 ${theme.newTheme.spacing(12)} 0`}>
        <Pagination
          to="/expert-guides"
          toPage="/expert-guides/page/"
          numPages={numPages}
          currentPage={currentPage}
        />
      </PageSection>
    </Layout>
  );
};

Guides.propTypes = {
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
};

Guides.defaultProps = {
  currentPage: null,
  numPages: null,
};

export default Guides;
