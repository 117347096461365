import React from 'react';
import PropTypes from 'prop-types';

import Post from 'atomic-design/molecules/post';
import { Container } from './styles';
import { validateBlogPostEntry } from '../../../../common/validations';
import MissingDataCard from '../../atoms/missing-data-card';
import { getCategoryColor } from 'common/helpers';

const GuideEntries = ({ posts }) => {
  const requiredFields = ['title', 'subtitle', 'slug', 'hero', 'publishedDate'];
  return (
    <Container>
      {Array.isArray(posts) &&
        posts.map(edge =>
          validateBlogPostEntry(edge.node) ? (
            <Post
              key={edge.node.slug}
              slug={edge.node.slug}
              title={edge.node.title}
              subtitle={edge.node.subtitle}
              hero={edge.node.hero?.contentful_id}
              categoryName="Guide"
              background={getCategoryColor('guide')}
              publishedDate={edge.node.publishedDate}
              maxWidthText="50%"
              minWidthText="50%"
              type="expert-guides"
            />
          ) : (
            <MissingDataCard
              key={edge.node.slug}
              obj={edge.node}
              reqFields={requiredFields}
              height="18rem"
            ></MissingDataCard>
          )
        )}
    </Container>
  );
};

GuideEntries.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        hero: PropTypes.string.isRequired,
      }),
    })
  ),
};

GuideEntries.defaultProps = {
  posts: [],
};

export default GuideEntries;
